import {
  home, campaign, articleDetail, articleListing, causeDetail, survey, coupons, olympicsSelectModal,
} from '../../../constants/page-source'

module.exports = {
  [home]: {
    default: {},
    Blue: {
      calloutExternal: 'calloutExternal pr-17 w-297 lg:w-4/12',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center mb-17 bg-white shadow-articalmd border-t-2 border-primary lg:h-184 xxl:h-194',
      calloutWrapper: 'callout-container flex w-full',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: '  lg:w-full min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full lg:w-185 lg:h-185 xxl:h-195 xxl:w-195',
      calloutIconwraper:
        'callout-iconwrapper   lg:w-full h-117 lg:h-194 xl:w-194 xxl:h-194 bg-bgcolor flex items-center justify-center',
      calloutIcon: 'w-60 h-60 lg:w-72 lg:h-72',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-15 pr-10 lg:px-10 xxl:pl-05 xxl:pr-20 lg:pt-30 xxl:pt-40 pt-15',
      title:
        'text-14 font-montserratBold font-bold text-primary text-left leading-seller18 lg:pb-10 pb-02 tracking-widest',
      subTitle:
        'callout-subTitle text-14 lg:text-18 lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    Teal: {
      calloutExternal: 'calloutExternal pr-17 w-297 lg:w-4/12',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center mb-17 bg-white shadow-articalmd border-t-2 border-secondary lg:h-184 xxl:h-194',
      calloutWrapper: 'callout-container flex w-full',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: '  lg:w-full min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full lg:w-185 lg:h-185 xxl:h-195 xxl:w-195',
      calloutIconwraper:
        'callout-iconwrapper   lg:w-full h-117 lg:h-194 xl:w-194 xxl:h-194 bg-SaveSecondaryColor flex items-center justify-center',
      calloutIcon: 'w-60 h-60 lg:w-72 lg:h-72',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-15 pr-10 lg:px-10 xxl:pl-05 xxl:pr-20 lg:pt-30 xxl:pt-40 pt-15',
      title:
        'text-14 font-montserratBold font-bold text-secondary text-left leading-seller18 lg:pb-10 pb-02 tracking-widest',
      subTitle:
        'callout-subTitle text-14 lg:text-18 lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    Red: {
      calloutExternal: 'calloutExternal pr-17 w-297 lg:w-4/12',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center mb-17 bg-white shadow-articalmd border-t-2 border-warning lg:h-184 xxl:h-194',
      calloutWrapper: 'callout-container flex w-full',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: '  lg:w-full min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full lg:w-185 lg:h-185 xxl:h-195 xxl:w-195',
      calloutIconwraper:
        'callout-iconwrapper rewards-callout   lg:w-full h-117 lg:h-184 xl:w-194 xxl:h-194 bg-rewardsColor flex items-center justify-center',
      calloutIcon: 'w-60 h-60 lg:w-72 lg:h-72',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-15 pr-10 lg:pl-10 lg:pr-09 xxl:pl-05 xxl:pr-20 lg:pt-30 xxl:pt-40 pt-15 ',
      title:
        'text-14 font-montserratBold font-bold text-warning text-left leading-seller18 lg:pb-10 pb-02 tracking-widest',
      subTitle:
        'callout-subTitle text-14 lg:text-18 lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    Green: {
      calloutExternal: 'calloutExternal pr-17 w-297 lg:w-4/12',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center mb-17 bg-white shadow-articalmd border-t-2 border-success lg:h-184 xxl:h-194',
      calloutWrapper: 'callout-container flex w-full',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: '  lg:w-full min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full lg:w-185 lg:h-185 xxl:h-195 xxl:w-195',
      calloutIconwraper:
        'callout-iconwrapper   lg:w-full h-117 lg:h-194 xl:w-194 xxl:h-194 bg-bgcolor flex items-center justify-center',
      calloutIcon: 'w-60 h-60 lg:w-72 lg:h-72',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-15 pr-10 lg:px-10 xxl:pl-05 xxl:pr-20 lg:pt-30 xxl:pt-40 pt-15',
      title:
        'text-14 font-montserratBold font-bold text-success text-left leading-seller18 lg:pb-10 pb-02 tracking-widest',
      subTitle:
        'callout-subTitle text-14 lg:text-18 lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    Magenta: {
      calloutExternal: 'calloutExternal  pr-17 w-297 lg:w-4/12',
      calloutMainWraper:
        'calloutMainWraper  w-full relative overflow-hidden flex items-center mb-17 bg-white shadow-articalmd border-t-2 border-info lg:h-184 xxl:h-194',
      calloutWrapper: 'callout-container flex w-full',
      SurvyeImageWrapper: 'w-full relative',
      calloutImagewraper: '  lg:w-full min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full lg:w-185 lg:h-185 xxl:h-195 xxl:w-195',
      calloutIconwraper:
        'callout-iconwrapper   lg:w-full h-117 lg:h-194 xl:w-194 xxl:h-194 bg-EarnInfoColor flex items-center justify-center',
      calloutIcon: 'w-60 h-60 lg:w-72 lg:h-72',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-15 pr-10 lg:px-10 xxl:pl-05 xxl:pr-20 lg:pt-30 xxl:pt-40 pt-15',
      title:
        'text-14 font-montserratBold font-bold text-info text-left leading-seller18 lg:pb-10 pb-02 tracking-widest',
      subTitle:
        'callout-subTitle text-14 lg:text-18 lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    'Dark Blue': {
      calloutExternal: 'calloutExternal pr-17 w-297 lg:w-4/12',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center mb-17 bg-white shadow-articalmd border-t-2 border-receiptFeedback lg:h-184 xxl:h-194',
      calloutWrapper: 'callout-container flex w-full',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: '  lg:w-full min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full lg:w-185 lg:h-185 xxl:h-195 xxl:w-195',
      calloutIconwraper:
        'callout-iconwrapper   lg:w-full h-117 lg:h-194 xl:w-194 xxl:h-194 bg-EarnPrimaryColor flex items-center justify-center',
      calloutIcon: 'w-60 h-60 lg:w-72 lg:h-72',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pl-05 xxl:pr-20 lg:pt-30 xxl:pt-40 pt-15',
      title:
        'text-14 lg:text-14 font-montserratBold font-bold text-receiptFeedback text-left leading-seller18 lg:pb-10 pb-02 tracking-widest',
      subTitle:
        'callout-subTitle text-14 lg:text-18 lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    homeHelpCarousel: {
      calloutExternal: 'calloutExternal w-full',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center md:mb-10px xl:mb-17 bg-white ',
      calloutWrapper:
        'callout-container md:flex w-full xl:min-h-370 items-center',
      SurvyeImageWrapper: 'w-full relative',
      calloutImagewraper: ' relative lg:w-full min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full ',
      calloutIconwraper:
        'callout-iconwrapper w-full flex items-center justify-center pt-10 md:pt-0 md:w-auto md:block md:absolute md:top-0 xl:top-4 md:-right-110 xl:-right-125',
      calloutIcon: 'w-100 h-100 xl:w-120 h-120',
      calloutSectionwraper:
        'calloutHelpSectionwraper w-full md:w-10/12 mx-auto text-center pt-20 md:pt-0 pb-50 md:pb-10 px-30',
      title:
        'w-full text-14 font-montserratBold font-bold text-receiptFeedback leading-seller18 pb-10 tracking-1 xl:tracking-onethree',
      subTitle:
        'w-full md:w-9/12 xl:w-9/12 xxl:w-8/12 mx-auto xl:px-05 callout-subTitle whitespace-break-spaces text-24 xl:text-32 xl:leading-leading42 font-montserratSemiBold font-bold leading-widest',
      linkTitle: 'btn btn-primary',
      subText:
        'w-full md:w-12/12 xl:w-9/12 xxl:w-8/12 md:px-10 lg:px-15 xl:px-10 mx-auto pt-05 pb-30 leading-lightmedium font-montserratlight font-light xl:text-18 xl:leading-widest',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
  },
  [campaign]: {
    default: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white border-t border-borderGrey',
      calloutWrapper: 'callout-container w-full pb-0',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-full',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper: 'calloutSectionwraper w-full',
      title:
        'videoWrapper text-14 xl:text-14 font-montserratBold font-bold text-warning text-left xl:leading-lightmedium leading-seller18 pt-15 xl:pt-20 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignBlue: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white border-t border-primary',
      calloutWrapper: 'callout-container flex w-full py-25',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pl-05 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-primary text-left xl:leading-lightmedium leading-seller18 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      subText:
        'font-montserratlight hidden md:block subText font-light text-left text-14 left-2 bottom-15',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignRed: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white border-t border-warning',
      calloutWrapper: 'callout-container flex w-full py-25',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pl-05 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-warning text-left xl:leading-lightmedium leading-leading20 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignRedV1: {
      calloutExternal:
        'calloutExternal w-full md:w-280 xl:w-290 xxl:w-310 mx-auto px-10',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white sm:border-t-1 md:border-t-0 border-warning',
      calloutWrapper:
        'callout-container flex md:flex-wrap md:justify-center w-full pt-10 pb-25',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full md:flex justify-center',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full md:flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper text-left md:text-center w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 ',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-warning xl:leading-lightmedium leading-leading20 lg:py-08 py-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignChooseRed: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white border-t border-warning',
      calloutWrapper: 'callout-container w-full pb-25',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-full',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-03 lg:pr-10 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-warning text-left xl:leading-lightmedium leading-seller18 pt-15 xl:pt-20 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignSweepsRed: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white border-t border-warning',
      calloutWrapper: 'callout-container w-full pb-25',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: ' mx-auto w-232 lg:px-05',
      calloutImage: 'w-full',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-03 lg:pr-10 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-warning text-left xl:leading-lightmedium leading-seller18 pt-15 xl:pt-20 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-16 pt-25 pb-15 text-sign-head leading-lightmedium font-montserratSemiBold font-semibold text-left',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignChooseVideo: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white border-t border-warning',
      calloutWrapper: 'callout-container w-full pb-25',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: 'lg:w-full',
      calloutImage: 'w-full',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper: 'calloutSectionwraper w-full',
      title:
        'videoWrapper text-14 xl:text-14 font-montserratBold font-bold text-warning text-left xl:leading-lightmedium leading-seller18 pt-15 xl:pt-20 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignDonateRed: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center bg-white border-t border-warning',
      calloutWrapper: 'callout-container w-full pb-25',
      SurvyeImageWrapper: 'hidden',
      calloutImagewraper: '  lg:w-full',
      calloutImage: '',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-03 lg:pr-10 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-warning text-left xl:leading-lightmedium leading-seller18 pt-15 xl:pt-20 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignTealV1: {
      calloutExternal:
        'calloutExternal w-full md:w-280 xl:w-290 xxl:w-310 mx-auto px-10',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center bg-white sm:border-t-1 md:border-0 border-secondary',
      calloutWrapper:
        'callout-container flex md:flex-wrap md:justify-center w-full pt-15 md:pt-05 pb-45 relative',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full flex justify-center',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pr-10',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-secondary text-left md:text-center xl:leading-lightmedium leading-seller18 pt-05 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left md:text-center leading-leading20',
      subText: 'callout-subText absolute bottom-0 left-0',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignDarkBlueV1: {
      calloutExternal:
        'calloutExternal w-full md:w-280 xl:w-290 xxl:w-310 mx-auto px-10',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center bg-white sm:border-t-1 md:border-0 border-darkBlue',
      calloutWrapper:
        'callout-container flex md:flex-wrap md:justify-center w-full pt-15 md:pt-05 pb-45 relative',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full flex justify-center',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pr-10',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-darkBlue text-left md:text-center xl:leading-lightmedium leading-seller18 pt-05 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left md:text-center leading-leading20',
      subText: 'callout-subText absolute bottom-0 left-0',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignTeal: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center bg-white border-t border-secondary',
      calloutWrapper: 'callout-container flex w-full pt-25 pb-45 relative',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pl-05 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-secondary text-left xl:leading-lightmedium leading-seller18 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      subText: 'callout-subText absolute bottom-0 left-0',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignFindTeal: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center bg-white border-0 border-secondary',
      calloutWrapper: 'callout-container flex w-full py-25',
      SurvyeImageWrapper: 'hidden',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pl-05 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-secondary text-left xl:leading-lightmedium leading-seller18 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignTideTeal: {
      calloutExternal:
        'calloutExternal campaignTide-wrapper w-full md:w-10/12 xl:w-8/12 pt-22 xl:pt-03 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper pointer-events-none w-full relative overflow-hidden flex items-center bg-white border-t border-borderGrey',
      calloutWrapper: 'callout-container flex w-full py-25',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-10 pr-20 lg:pl-10 lg:pr-03 xxl:pl-05 xxl:pr-20',
      title:
        'text-14 font-montserratBold font-bold text-primary text-left xl:leading-lightmedium leading-seller18 lg:pb-05 pb-02 tracking-1 md:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-16 pr-05 md:pr-22 lg:pr-0 text-sign-head font-montserratSemiBold font-semibold text-left leading-leading20 xl:leading-light',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignWithNoTopBorder: {
      calloutExternal:
        'calloutExternal campaignTide-wrapper w-full md:w-10/12 xl:w-8/12 pt-22 xl:pt-03 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper pointer-events-none w-full relative overflow-hidden flex items-center bg-white',
      calloutWrapper: 'callout-container flex w-full pt-10 pb-25',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-10 pr-20 lg:pl-10 lg:pr-03 xxl:pl-05 xxl:pr-20',
      title:
        'text-14 font-montserratBold font-bold text-primary text-left xl:leading-lightmedium leading-seller18 lg:pb-05 pb-02 tracking-1 md:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-16 pr-05 md:pr-22 lg:pr-0 text-sign-head font-montserratSemiBold font-semibold text-left leading-leading20 xl:leading-light',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignSwifferCoupons: {
      calloutExternal:
        'calloutExternal w-full md:w-10/12 xl:w-8/12 xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden flex items-center bg-white border-t border-swifferCoupons',
      calloutWrapper: 'callout-container flex w-full pt-25 pb-45 relative',
      SurvyeImageWrapper: 'w-3/12 xl:w-4/12',
      calloutImagewraper: '  lg:w-full',
      calloutImage: 'w-47 h-47 xl:w-66 xl:h-66',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full md:pl-15 pr-10 lg:pl-10 lg:pr-10 xxl:pl-05 xxl:pr-20',
      title:
        'text-14 xl:text-14 font-montserratBold font-bold text-swifferCoupons text-left xl:leading-lightmedium leading-seller18 lg:pb-05 pb-02 tracking-1 xl:tracking-onethree',
      subTitle:
        'callout-subTitle text-14 xl:text-18 text-sign-head lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      subText: 'callout-subText absolute bottom-0 left-0',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    campaignSweepstakes: {
      calloutExternal:
        'calloutExternal bg-rewardsBg py-30 xl:py-55 w-full xl:px-08 mx-auto',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden focus:outline-none cursor-default flex items-center',
      calloutWrapper:
        'callout-container w-full flex flex-wrap flex-col-reverse justify-center',
      SurvyeImageWrapper: 'w-full',
      calloutImagewraper: ' mx-auto w-full px-10 md:w-430 lg:px-05',
      calloutImage: 'w-full',
      calloutIconwraper:
        'callout-iconwrapper lg:w-full flex items-center justify-center',
      calloutIcon: 'w-46 h-46 lg:w-66 lg:h-66',
      calloutSectionwraper:
        'calloutSectionwraper w-full pl-03 lg:pr-10 xxl:pr-20',
      title:
        'callout-title',
      subTitle:
        'callout-subTitle text-24 xl:text-32 pb-20 xl:pb-50 text-sign-head leading-widest font-bold',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
  },
  [articleListing]: {
    default: {},
    Blue: {
      calloutExternal:
        'surveycardouter w-full blue  md:w-6/12 md:pb-10  mt-7px md:mt-10px lg:mt-7px',
      calloutMainWraper: 'surveycardinner relative w-full  bg-white h-full',
      calloutWrapper: 'surveycard  bg-white w-full  flex items-center',
      SurvyeImageWrapper: 'surveyimageWrapper w-5/12 relative pr-10',
      calloutIconwraper:
        'surveyimage surveryBlue  flex justify-center items-center rounded-tl-xl mb-10px items-center ml-10px mr-5px lg:mr-15px',
      calloutSectionwraper:
        'surveycontent flex  items-center w-7/12 pt-0 lg:pt-0 flex-wrap lg:pr-20 lg:pl-35 pr-10',
      title: 'hidden',
      subTitle:
        'text-base text-left leading-ultralight font-montserratSemiBold font-semibold pb-30 xl:text-26 xl:leading-wider lg:pb-20 md:pb-10',
      linkTitle: '',
      surveycardhash:
        'surveycardhash hi border-t-threefourth w-full absolute bottom-10 md:bottom-5 lg:bottom-10 flex items-center justify-around lg:justify-center pt-08 text-input-br',
    },
    Teal: {},
    Red: {},
    Green: {},
    Magenta: {
      calloutExternal:
        'surveycardouter w-full magenta bg-white md:w-6/12 md:mb-10px  mt-7px md:mt-10px lg:mt-7px',
      calloutMainWraper: 'surveycardinner relative w-full  bg-white h-full',
      calloutWrapper:
        'surveycard  bg-white w-full  flex items-center lg:pt-11 pt-08',
      SurvyeImageWrapper: 'surveyimageWrapper w-5/12 relative pr-10',
      calloutIconwraper:
        'surveyimage   flex justify-center items-center rounded-tl-xl mb-10px items-center ml-10px mr-5px lg:mr-15px',
      calloutSectionwraper:
        'surveycontent flex  items-center w-7/12 pt-0 lg:pt-0 flex-wrap lg:pr-20 lg:pl-35 pr-10',
      title: 'hidden',
      subTitle:
        'text-base text-left leading-ultralight font-montserratSemiBold font-semibold pb-30 xl:text-26 xl:leading-wider lg:pb-20 md:pb-10',
      linkTitle: '',
      surveycardhash:
        'surveycardhash hi border-t-threefourth w-full absolute bottom-10 md:bottom-5 lg:bottom-10 flex items-center justify-around lg:justify-center pt-08 text-input-br',
    },
    'Dark Blue': {},
  },
  [articleDetail]: {
    default: {},
    Blue: {
      calloutExternal:
        'calloutExternal w-full md:w-7/12 lg:w-6/12 flex items-center mt-5 md:mt-0 px-01 pb-05',
      calloutMainWraper: 'w-full flex items-center mt-0 md:mt-0 px-01',
      calloutWrapper:
        'w-full flex callout-container flex-wrap bg-white border-t border-l border-lightgrey border-borderCallout rounded-1lg shadow-aboutCard px-15 py-45 md:py-15 md:px-10 lg:pr-25 lg:pl-0 lg:pt-25 lg:pb-30',
      SurvyeImageWrapper:
        'w-5/12 items-center flex px-10 md:p-15 lg:pt-25 lg:px-30',
      calloutImagewraper: '',
      calloutIconwraper: '',
      calloutImage: 'w-full SurvyeImageWrapper',
      calloutSectionwraper: 'w-7/12 items-center pl-0 text-left',
      title:
        'calloutTitle w-full font-montserratBold font-bold text-16 lg:text-24 leading-lightmedium lg:leading-widest pt-0 lg:pt-25 pb-10 lg:pb-10',
      subTitle:
        'calloutSubtitle w-full font-montserratlight font-light text-14 lg:text-16 leading-lightmedium md:pr-0 pb-05',
      linkTitle:
        'w-full flex callout-icon font-montserratBold font-bold text-blue text-16 lg:text-23 pt-10 leading-ultralight pb-05',
      linkButton: '',
    },
    Teal: {},
    Red: {},
    Green: {},
    Magenta: {},
    'Dark Blue': {},
  },
  default: {
    default: {},
    Blue: {},
    Teal: {},
    Red: {},
    Green: {},
    Magenta: {},
    'Dark Blue': {},
  },
  deqGoodnews: {
    calloutExternal: 'surveycardouter w-full   md:w-6/12 mt-10px',
    calloutMainWraper: 'surveycardinner relative w-full  bg-white h-full',
    calloutWrapper: 'surveycard  bg-white w-full  flex items-center',
    SurvyeImageWrapper: 'surveyimageWrapper w-5/12 relative pr-10',
    calloutIconwraper:
      'surveyimage   flex justify-center items-center rounded-tl-xl mb-10px items-center ml-10px mr-5px lg:mr-15px',
    calloutSectionwraper:
      'surveycontent flex  items-center w-7/12 pt-0 lg:pt-0 flex-wrap lg:pr-20 lg:pl-35 pr-10',
    title: 'hidden',
    subTitle:
      'text-base text-left leading-lightmedium font-montserratSemiBold font-semibold pb-30 lg:text-26 lg:leading-wider lg:pb-20 md:pb-10',
    linkTitle: '',
    surveycardhash:
      'surveycardhash border-t-threefourth w-full absolute bottom-10 flex items-center justify-around lg:justify-center pt-08 text-input-br',
  },
  'article-detail-recycle': {
    calloutExternal:
      'calloutExternal w-full md:w-7/12 lg:w-6/12 flex items-center mt-5 md:mt-0 px-01 mb-05',
    calloutMainWraper: 'w-full flex items-center mt-0 md:mb-10px px-01',
    calloutWrapper:
      'w-full flex callout-container flex-wrap bg-white border-t border-l border-lightgrey border-borderCallout rounded-1lg shadow-aboutCard px-15 py-45 md:py-15 md:px-10 lg:pr-25 lg:pl-0 lg:pt-25 lg:pb-30',
    SurvyeImageWrapper:
      'w-5/12 items-center flex px-10 md:p-15 lg:pt-25 lg:px-30',
    calloutImagewraper: '',
    calloutIconwraper: '',
    calloutSectionwraper: 'w-7/12 items-center pl-0 text-left',
    title:
      'calloutTitle w-full font-montserratBold font-bold text-16 lg:text-24 leading-lightmedium lg:leading-widest pt-0 lg:pt-25 pb-10 lg:pb-10',
    subTitle:
      'calloutSubtitle w-full font-montserratlight font-light text-14 lg:text-16 leading-lightmedium md:pr-0 pb-05',
    linkTitle:
      'w-full flex callout-icon font-montserratBold font-bold text-blue text-16 lg:text-23 pt-10 leading-ultralight pb-05',
    linkButton: '',
  },
  [causeDetail]: {
    default: {},
    Teal: {},
    Red: {},
    Green: {},
    Magenta: {},
    'Dark Blue': {},
  },
  [survey]: {
    default: {},
    Magenta: {
      calloutExternal:
        'calloutExternal relative survey-calloutWrapper mx-auto flex items-center mb-30 mx-9px md:mx-13px lg:mx-11px xxl:mr-15px xxl:ml-13px shadow-articalmd w-5/12 h-159 md:w-174 md:h-170 xl:w-218 xl:h-220 bg-white',
      completedBtn:
        'tileDeqInactive w-full absolute top-0 right-0 text-white text-14 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
      completedGetBonusBtn:
        'tileDeqInactive completed-bonus w-full absolute top-0 right-0 text-white text-14 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
      calloutMainWraper: 'calloutMainWraper w-full h-full relative ',
      calloutWrapper:
        'callout-container w-full text-14 xl:text-16 font-montserratSemiBold font-semibold leading-lightmedium',
      SurvyeImageWrapper: 'w-full relative flex justify-center',
      calloutImagewraper: '  lg:w-full ',
      calloutImage: 'h-auto ',
      calloutIconwraper: 'callout-iconwrapper ',
      calloutIcon: 'w-50 h-50 md:w-60 md:h-60 xl:w-80 xl:h-80 pb-15',
      calloutSectionwraper: 'calloutSectionwraper ',
      title:
        'text-14 lg:text-16 leading-leading20 font-montserratSemiBold font-semibold leading-lightmedium',
      subTitle:
        'callout-subTitle text-14 lg:text-18 lg:leading-lightmedium font-montserratSemiBold font-semibold text-left leading-leading20',
      linkTitle: '',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    Blue: {
      calloutExternal:
        'calloutExternal w-10/12 md:w-7/12 xl:w-5/12 mx-auto pt-45 pb-35 md:pb-30 xl:pb-40',
      calloutMainWraper: 'w-full flex items-center focus:outline-none ',
      calloutWrapper: 'calloutWrapper',
      SurvyeImageWrapper: 'SurvyeImageWrapper',
      calloutImagewraper: 'calloutImagewraper',
      calloutIconwraper: 'calloutIconwraper',
      calloutSectionwraper: 'calloutSectionwraper',
      title:
        'calloutTitle px-10 font-montserratSemiBold font-semibold text-30 md:text-24 leading-wider md:leading-widest xl:text-38 xl:leading-leading42',
      subTitle:
        'calloutSubtitle p-20 font-montserratSemiBold font-semibold text-16 leading-lightmedium',
      linkTitle:
        'w-auto mx-auto flex justify-center btn btn-default text-primary',
      linkButton: '',
    },
  },
  [coupons]: {
    default: {},
  },
  [olympicsSelectModal]: {
    default: {
      calloutExternal: 'calloutExternal w-full',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden bg-white ',
      calloutWrapper: 'callout-container  w-full md:mx-30px items-center',
      SurvyeImageWrapper: 'w-full relative',
      calloutImagewraper:
        ' relative lg:w-full px-20 md:px-0 min-h-130 xl:min-h-185',
      calloutImage: 'h-auto w-full min-h-200',
      calloutIconwraper:
        'callout-iconwrapper w-full flex items-center justify-center pt-10 md:pt-0 md:w-auto md:block md:absolute md:top-0 xl:top-4 md:-right-110 xl:-right-125',
      calloutIcon: 'w-100 h-100 xl:w-120 h-120',
      calloutSectionwraper:
        'calloutHelpSectionwraper text-black w-full md:w-10/12 mx-auto text-center pt-20 md:pt-05 md:pb-10 px-30',
      title: 'w-full font-bold uppercase text-24 leading-leading26',
      subTitle: 'w-full leading-light',
      linkTitle: 'btn btn-black mt-50px',
      subText:
        'w-full md:w-12/12 xl:w-9/12 xxl:w-8/12 md:px-10 lg:px-15 xl:px-10 mx-auto pt-05 pb-30 leading-lightmedium font-montserratlight font-light xl:text-18 xl:leading-widest',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
    olympicsChooseVideo: {
      calloutExternal: 'calloutExternal w-full px-0 lg:w-834',
      calloutMainWraper:
        'calloutMainWraper w-full relative overflow-hidden olympicVideoWrapper ',
      calloutWrapper: 'callout-container py-20 w-full md:px-0 items-center',
      SurvyeImageWrapper: 'w-full relative flex justify-center',
      calloutImagewraper:
        ' relative px-0 md:px-30 w-full min-h-240 xl:min-h-340',
      calloutImage: 'h-auto w-full min-h-200',
      calloutIconwraper:
        'callout-iconwrapper w-full flex items-center justify-center pt-10 md:pt-0 md:w-auto md:block md:absolute md:top-0 xl:top-4 md:-right-110 xl:-right-125',
      calloutIcon: 'w-100 h-100 xl:w-120 h-120',
      calloutSectionwraper:
        'calloutHelpSectionwraper text-black w-full md:w-full bg-white mx-auto text-center pt-20 md:pt-05 md:pb-10 px-30',
      title: 'w-full font-bold uppercase text-24 leading-leading26',
      subTitle: 'w-full leading-light',
      linkTitle: 'btnbtn-black mt-50px hidden',
      subText:
        'w-full md:w-12/12 xl:w-9/12 xxl:w-8/12 md:px-10 lg:px-15 xl:px-10 mx-auto pt-05 pb-30 leading-lightmedium font-montserratlight font-light xl:text-18 xl:leading-widest',
      linkButton: '',
      surveycardhash: 'surveycardhash hidden',
    },
  },
}
