import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import aimiaStore from '../../../containers/AIMIA/aimia.store'
import Image from '../../Image/Image'
import Typography from '../../Typography/Typography'
import LinkButton from '../../LinkButton/LinkButton'
import Icon from '../../Icon/Icon'
import Button from '../../Button'
import RichText from '../../RichText/RichText'
import ReceiptScanStore from '../../../containers/ReceiptScan/ReceiptScan.store'
import calloutJsxStyles from './Callout.styles'
import {
  feedbackSurvey,
  isSurveyCompleted,
  isSegmantaLoaded,
  getMasterSurveyID,
} from '../../../helpers/segmanta.helper'
import {
  MonthlySweepsIdNonProd, MonthlySweepsIdProd, rewardType, serveySubtitle, sweepsstakesCampaignText,
} from '../../../constants/texts'
import getSurveyId from '../../../containers/SurveyLanding/Survey.helper'
import twStyle from './Callout.tw.styles'
import LoginStore from '../../UserManagement/Login-store'
import { carousalButtonClickEvent } from '../../../helpers/gaEvent.helper'
import { olympicsSelectModal } from '../../../constants/page-source'

const Callout = ({
  title,
  subTitle,
  text,
  link,
  image,
  variant,
  featuredCallout,
  surveyName,
  pageSource,
  iconName,
  callToActionsCollection,
  DEQCallout,
  behaviour,
  featuredSurvey,
  monthlyPromoRewardId,
  index,
  mosStartAndEndDate,
}) => {
  const [surveyCompleted, setSurveyCompleted] = useState(false)
  const [isFeatureCallout, setFeatureCallout] = useState(false)
  const [weeklySweepsDetail, setWeeklySweepsDetail] = useState(null)

  const handleRedirectUrl = (url) => {
    if (LoginStore.rewardStatus) return `/account-setup/${url}`
    return `signup/${url}`
  }
  const url = behaviour === 'Sample' ? handleRedirectUrl(link) : link

  const pageStyle = twStyle[pageSource] || twStyle.default
  const style = pageStyle[variant] || pageStyle.default
  let surveyID = ''
  const getID = async () => {
    surveyID = await getMasterSurveyID(featuredSurvey, monthlyPromoRewardId, mosStartAndEndDate)
  }
  if (surveyName?.surveyName) {
    if (surveyName.surveyName === 'DEQ_MASTER' && LoginStore.rewardStatus) getID()
    else surveyID = getSurveyId(surveyName.surveyName)
  }

  const homePageCarousalButtonEvent = (event) => {
    // carousalButtonClickEvent(window?.location.origin + event)
    carousalButtonClickEvent(`${window?.location.origin} ${event}`)
  }

  const getURL = () => (url !== '/?recieptscan' ? url : null)
  const handleGetWeeklySweepsDetails = async () => {
    const MonthlySweepsId = (process.env.OKTA_ENVIRONMENT === 'production' ? MonthlySweepsIdProd : MonthlySweepsIdNonProd)
    await aimiaStore.fetchAllRewards(LoginStore.userLoggedIn, [])
    const weeklySweeps = aimiaStore.rewards
      .filter((reward) => reward.type === rewardType.sweeps && reward.id === MonthlySweepsId)[0]
    setWeeklySweepsDetail(weeklySweeps)
  }

  const handleGetWeeklySweepsDetailsContent = (data) => {
    if (!data) {
      return (
        <>
          <div className='w-18 md:w-24'>
            <Icon name='spinnerCoral' />
          </div>
        </>
      )
    }
    return (
      <>
        <div className='w-full pt-10 bg-rewardsBg shadow-campaignTile'>
          {image && (
            <div className={style.calloutImagewraper}>
              <Image {...image} />
            </div>
          )}
          <div className='text-14  pt-08 pb-05 font-montserratBold font-bold leading-lightsmall'>
            <div className='flex flex-wrap justify-center'>
              {weeklySweepsDetail?.cost}
              <Icon spanStyle='sweeps-icon flex items-center pl-05 ' name='points' />
            </div>
            <div className=' tracking-1Point56 leading-small uppercase'>{weeklySweepsDetail?.name}</div>
          </div>
        </div>
        <div className='italic font-medium pt-30 text-left'>
          {sweepsstakesCampaignText}
        </div>
      </>
    )
  }

  useEffect(() => {
    if (DEQCallout && pageSource !== 'articleListing' && surveyName.surveyName !== 'DEQ_MASTER') {
      isSegmantaLoaded(async () => {
        const status = await isSurveyCompleted(surveyID)
        setSurveyCompleted(status)
      })
    }
    if (featuredCallout === true) {
      setFeatureCallout(true)
    }
    if (behaviour === 'Weekly_Sweeps') {
      handleGetWeeklySweepsDetails()
    }
  }, [])
  return (
    <div className={style.calloutExternal}>
      {(surveyCompleted
        || LoginStore.filteredSurveyList.indexOf(parseInt(surveyID, 10)) >= 0) && (
          <div>
            <div className={style.completedBtn}>COMPLETED</div>
            <div className='letsGetStartedTile  letsGetStartedCallout' />
          </div>
      )}
      {LoginStore.filteredSurveyList.indexOf(parseInt(surveyID, 10)) < 0
        && !surveyCompleted
        && isFeatureCallout && (
          <Button
            to={getURL()}
            className={style.completedGetBonusBtn}
            handler={() => {
              if (url === '/?recieptscan') ReceiptScanStore.setWidgetVisibility(true)
              else if (DEQCallout) feedbackSurvey(surveyID)
            }}
          >
            GET BONUS!
          </Button>
      )}
      <Button
        to={getURL()}
        className={style.calloutMainWraper}
        role={behaviour === 'Carousel' ? 'banner' : ''}
        aria-label={`${subTitle || 'sub-title'}`}
        handler={() => {
          if (url === '/?recieptscan') ReceiptScanStore.setWidgetVisibility(true)
          else if (DEQCallout) feedbackSurvey(surveyID)
          else if (pageSource === 'Home') homePageCarousalButtonEvent(link)
        }}
      >
        <div className={style.calloutWrapper}>
          <div className={style.SurvyeImageWrapper}>
            {image && behaviour !== 'Weekly_Sweeps' && (
              <div className={style.calloutImagewraper}>
                <div className={`${variant === 'olympicsChooseVideo' ? 'w-full h-full' : ''}`}>
                  {variant === 'olympicsChooseVideo'
                    ? <iframe width='100%' height='100%' title='olympics Video' src={callToActionsCollection.items[0]?.link} />
                    : <Image priority={pageSource === 'Home' && index === 0} className={style.calloutImage} {...image} />}
                </div>
              </div>
            )}
            {iconName && (
              <div className={style.calloutIconwraper}>
                {iconName && <Icon className={style.calloutIcon} name={LoginStore.filteredSurveyList.indexOf(parseInt(surveyID, 10)) < 0 && !surveyCompleted && isFeatureCallout ? 'featureSurvey' : iconName} />}
              </div>
            )}
          </div>
          {pageSource === 'survey' && variant === 'Magenta' && (
            <>
              <RichText
                body={serveySubtitle}
                renderers={{
                  link: ({ href }) => (
                    <>
                      {href === 'causeSurveyMini' && (
                        <Icon className='h-13 inherit' name='causeSurveyMini' />
                      )}
                      {href === 'sparksSurveyMini' && (
                        <Icon
                          className='h-13 inherit'
                          name='sparksSurveyMini'
                        />
                      )}
                    </>
                  ),
                }}
              />
            </>
          )}
          <div className={style.calloutSectionwraper}>
            {title && <Typography markdown={title} className={style.title} />}
            {subTitle && (
              <Typography markdown={subTitle} className={style.subTitle} />
            )}
            {text && <Typography className={style.subText} markdown={text} />}

            {callToActionsCollection
              && callToActionsCollection.items.map((item) => (
                <LinkButton
                  {...item}
                  key={item && item.sys && item.sys.id}
                  className={style.linkTitle}
                  handler={pageSource === 'Home' ? () => { homePageCarousalButtonEvent(item?.link || link) } : () => { }}
                  link={pageSource === olympicsSelectModal ? '#' : item.link} // temporaty fixed added to block unnecessary redirection
                />
              ))}
          </div>
          {behaviour === 'Weekly_Sweeps' && (
            <div>
              <>
                {handleGetWeeklySweepsDetailsContent(weeklySweepsDetail)}
              </>
            </div>
          )}
          <div className={style.surveycardhash}>
            <span className=' text-14 items-center leading-points16 font-montserratlight font-light  flex lg:px-15 justify-between'>
              <span className='hashText'>#Artcile</span>
            </span>
          </div>
        </div>
      </Button>
      <style jsx>{calloutJsxStyles}</style>
    </div>
  )
}

Callout.propTypes = {
  image: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  button: PropTypes.string,
  callToActionsCollection: PropTypes.oneOfType([PropTypes.object]),
  link: PropTypes.string,
  iconName: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  featuredCallout: PropTypes.bool,
  pageSource: PropTypes.string,
  href: PropTypes.string,
  DEQCallout: PropTypes.bool,
  surveyName: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
  featuredSurvey: PropTypes.bool,
  monthlyPromoRewardId: PropTypes.string,
  behaviour: PropTypes.string,
  index: PropTypes.number,
  mosStartAndEndDate: PropTypes.instanceOf(Object),
}

Callout.defaultProps = {
  image: null,
  title: null,
  text: null,
  subTitle: null,
  button: null,
  link: null,
  callToActionsCollection: null,
  iconName: null,
  name: null,
  featuredCallout: null,
  variant: null,
  pageSource: null,
  href: null,
  surveyName: null,
  DEQCallout: false,
  featuredSurvey: false,
  monthlyPromoRewardId: '',
  behaviour: '',
  index: '',
  mosStartAndEndDate: null,
}

export default observer(Callout)
